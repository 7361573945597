.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.picture {
    max-width: 300px;
    max-height: 300px;
    padding-top: 39px;
}

.ProfileArea {
    display: inline-flex;
    border-bottom: 3px solid #000000;
}

.icon {
    max-width: 70px;
    max-height: 70px;
}

.projectDiv {
    font-Family: "Honor";
    font-Size: "55px";
    color: '#45A29E';
}

.projectDiv .projectHeader {
    margin: '10px';
}

.projectDiv .bodyText {
    font-Family: 'Kernel-Panic';
    font-Size: "60px";
    font-style: normal;
    color: "#C5C6C7";
}

@font-face {
    font-family: 'Disket-Mono';
    font-style: normal;
    src: url('Fonts/Disket-Mono-Regular.ttf');
}

@font-face {
    font-family: 'Kernel-Panic';
    src: url('Fonts/KernelPanicNbp-LyG3.ttf')
}

@font-face {
    font-family: 'Honor';
    src: url('Fonts/HonorMedium-GpaD.ttf')
}


/*
This keeps the items centered in the middle of the page
*/